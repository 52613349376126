import React, { createContext, useEffect, useState } from 'react';

import {
  IUserManagementApi,
  HelperApi,
  IConnectApi,
  HolidayApi,
  ClosureApi,
  HourApi,
  IContactFlowsApi,
  ISyncApi,
  IMenuManagementApi,
} from '@voicefoundry-cloud/vf-omp-shared';
import { ClosuresApiClient } from './ClosuresApiClient';
import { ConnectApiClient } from './ConnectApiClient';
import { ContactFlowsApiClient } from './ContactFlowsApiClient';
import { EntryPointManagementApiClient } from './ContactEntryPointsApiClient';
import { HelpersApiClient } from './HelpersApiClient';
import { HolidaysApiClient } from './HolidaysApiClient';
import AmplifyService from '@vf/services/AmplifyService';
import { UserManagementApiClient } from './UserManagementApiClient';
import { MenuManagementApiClient } from './MenuManagementApiClient';
import { QueueTreatmentApiClient } from './QueueTreatmentApiClient';
import { IPhoneNumberTaxonomyApiClient, PhoneNumberTaxonomyApiClient } from './PhoneNumberTaxonomyApiClient';
import { SyncApiClient } from './SyncApiClient';
import Loader from 'shared/components/Loader';
import { HoursApiClient } from './HoursApiClient';
import { useAppContext } from '../ContextProvider';

export interface IApiContext {
  contactflow: IContactFlowsApi;
  closure: ClosureApi;
  connect: IConnectApi;
  helpers: HelperApi;
  holiday: HolidayApi;
  hour: HourApi;
  sync: ISyncApi;
  menu: IMenuManagementApi;
  entryPoint: EntryPointManagementApiClient;
  queueTreatment: QueueTreatmentApiClient;
  userManagement: IUserManagementApi;
  phoneNumberTaxonomy: IPhoneNumberTaxonomyApiClient;
}

export const ApiContext = createContext<IApiContext>(null as any);

function ApiContextProvider(props: { children: any }) {
  const { config, user, updateAuthUser, enableTenancy, setTenancySetting, setAvailableFeatures } = useAppContext();

  const [state, setState] = useState<IApiContext>(null as any);
  useEffect(() => {
    if (config) {
      const useAuth = config.environment !== 'local';
      setState({
        contactflow: new ContactFlowsApiClient(config.apiUrl, useAuth),
        closure: new ClosuresApiClient(config.apiUrl, useAuth),
        connect: new ConnectApiClient(config.apiUrl, useAuth),
        helpers: new HelpersApiClient(config.apiUrl, useAuth),
        holiday: new HolidaysApiClient(config.apiUrl, useAuth),
        hour: new HoursApiClient(config.apiUrl, useAuth),
        sync: new SyncApiClient(config.apiUrl, useAuth),
        menu: new MenuManagementApiClient(config.apiUrl, useAuth),
        entryPoint: new EntryPointManagementApiClient(config.apiUrl, useAuth),
        queueTreatment: new QueueTreatmentApiClient(config.apiUrl, useAuth),
        userManagement: new UserManagementApiClient(config.apiUrl, useAuth),
        phoneNumberTaxonomy: new PhoneNumberTaxonomyApiClient(config.apiUrl, true),
      });
      if (!config.cognito) return;

      AmplifyService.setHubListener(updateAuthUser, enableTenancy, setTenancySetting, setAvailableFeatures);

      AmplifyService.isAuthenticated().then(bool => {
        if (bool) {
          AmplifyService.getClaims()
            .then(claims => {
              if (!claims) {
                AmplifyService.federatedLogin();
              } else {
                if (claims.groups.includes('omp-admin')) {
                  if (claims.tenancyEnabled) {
                    claims.groups.push('tenancyManagement');
                    enableTenancy(true);
                    if (claims.tenancyOn) {
                      setTenancySetting(true);
                    }
                  }
                  setAvailableFeatures(claims.features);
                  updateAuthUser({
                    username: claims.username,
                    userType: 'admin',
                    groups: claims.groups.concat(claims.features),
                    email: claims.email,
                  });
                } else {
                  if (claims.tenancyOn) {
                    setTenancySetting(true);
                  }
                  setAvailableFeatures(claims.features);
                  updateAuthUser({
                    username: claims.username,
                    userType: 'user',
                    groups: claims.groups.concat(claims.features),
                    email: claims.email,
                  });
                }
              }
            })
            .catch(err => {
              console.log(err);
              updateAuthUser(null);
            });
        } else {
          AmplifyService.federatedLogin();
        }
      });
    }
  }, [config, updateAuthUser]);

  return <ApiContext.Provider value={state}>{user && state ? props.children : <Loader />}</ApiContext.Provider>;
}

export default ApiContextProvider;
