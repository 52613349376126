import { ApiContext } from '@vf/utility/ApiContextProvider/ApiContextProvider';
import React, { useContext, useEffect, useReducer } from 'react';
import config from './config';
import ClosuresManagementContext from './ManagementContext';
import { IMessage } from 'types/models/IMessage';
import { reducer } from './reducer';
import { ENTITY_TYPES, getEntityAttrValueHelper } from 'contexts/SharedContextModels';
import ClosuresManagementContextPropsType from './ManagementContextPropTypes';
export const INITIAL_STATE = {
  ...config,
};

const ClosuresManagementContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const api = useContext(ApiContext);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  useEffect(() => {
    listClosures();
    listClosurePrompts();
    getQueues();
    getNumbers();
    getTimezones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  const listClosures = async () => {
    dispatch({ type: 'LOADING_CLOSURES', payload: true });
    try {
      const closuresList = await api.closure.getAllClosures();
      dispatch({ type: 'LIST', payload: { closuresList } });
    } catch (err) {
      dispatch({ type: 'LOADING_CLOSURES', payload: false });
      dispatch({
        type: 'SET_ERROR',
        payload: { error: 'Server error while loading closures data' },
      });
    }
  };

  const getQueues = async () => {
    try {
      const queues = await api.helpers.getAllQueues();
      dispatch({
        type: 'GET_QUEUES',
        payload: {
          queues,
        },
      });
    } catch (err) {
      console.warn('Unable to load queues', err);
    }
  };
  const getNumbers = async () => {
    try {
      const numbers = await api.helpers.getAllNumbers();
      dispatch({
        type: 'GET_NUMBERS',
        payload: {
          numbers,
        },
      });
    } catch (err) {
      console.warn('Unable to load claimed numbers', err);
    }
  };
  const getTimezones = async () => {
    try {
      const timezones = await api.helpers.getAllTimezones();
      dispatch({
        type: 'GET_TIMEZONES',
        payload: {
          timezones,
        },
      });
    } catch (err) {
      console.warn('Unable to load timezones', err);
    }
  };
  const listClosurePrompts = async () => {
    dispatch({ type: 'LOADING_PROMPTS', payload: true });
    try {
      const prompts = await api.closure.getClosurePrompts();
      dispatch({
        type: 'LIST_PROMPTS',
        payload: { prompts: prompts.prompts },
      });
    } catch (err) {
      dispatch({ type: 'LOADING_PROMPTS', payload: false });
      setMsg({
        message: 'Server error while loading prompts data',
        type: 'error',
      });
    }
  };

  const setMsg = (msg: IMessage) => {
    dispatch({ type: 'SET_MESSAGE', payload: { msg } });
  };

  const getEntityAttrValue = (type: ENTITY_TYPES, keyType: string, keyValue: string, returnAttr: string = null) =>
    getEntityAttrValueHelper(state, type, keyType, keyValue, returnAttr);

  return (
    <ClosuresManagementContext.Provider
      value={{
        ...state,
        setMsg,
        getEntityAttrValue,
        listClosures,
        listClosurePrompts,
      }}>
      {children}
    </ClosuresManagementContext.Provider>
  );
};

export const useClosuresContext = () => useContext<ClosuresManagementContextPropsType>(ClosuresManagementContext);

export default ClosuresManagementContextProvider;
