import { ENTITY_TYPES } from 'contexts/SharedContextModels';
import ClosuresManagementContextPropsType from './ManagementContextPropTypes';

const config: ClosuresManagementContextPropsType = {
  closuresList: [],
  closuresLoaded: false,
  loadingClosures: false,
  deletingClosures: false,
  prompts: [],
  promptsLoaded: false,
  loadingPrompts: false,
  queues: [],
  queuesLoaded: false,
  numbers: [],
  numbersLoaded: false,
  timezones: [],
  timezonesLoaded: false,
  error: '',
  msg: undefined,
  getEntityAttrValue: (type: ENTITY_TYPES, keyType: string, keyValue: string, returnAttr?: string) => undefined,
};

export default config;
