import { ApiContext } from '@vf/utility/ApiContextProvider/ApiContextProvider';
import React, { useContext, useEffect, useReducer } from 'react';
import config from './config';
import HolidaysManagementContext from './HolidaysManagementContext';
import { IMessage } from '../../types/models/IMessage';
import { reducer } from './reducer';
import { HolidayVm } from '@voicefoundry-cloud/vf-omp-shared';
import { ENTITY_TYPES, getEntityAttrValueHelper } from 'contexts/SharedContextModels';
import HoursManagementContextPropsType from './HolidaysManagementContextPropTypes';
export const initialState = {
  ...config,
};
const HolidaysManagementContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const api = useContext(ApiContext);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    listHolidays();
    listHolidayPrompts();
    getQueues();
    getNumbers();
    getTimezones();
  }, [api]);

  const listHolidays = async () => {
    dispatch({ type: 'LOADING_HOLIDAYS', payload: true });
    try {
      const holidaysList: HolidayVm[] = await api.holiday.getAllHolidays();
      dispatch({ type: 'LIST', payload: { holidaysList } });
    } catch (err) {
      dispatch({ type: 'LOADING_HOLIDAYS', payload: false });
      setMsg({
        message: 'Server error while loading holidays data',
        type: 'error',
      });
    }
  };

  const listHolidayPrompts = async () => {
    dispatch({ type: 'LOADING_PROMPTS', payload: true });
    try {
      const prompts = await api.holiday.getHolidayPrompts();
      dispatch({
        type: 'LIST_PROMPTS',
        payload: { prompts: prompts.prompts },
      });
    } catch (err) {
      dispatch({ type: 'LOADING_PROMPTS', payload: false });
      setMsg({
        message: 'Server error while loading prompts data',
        type: 'error',
      });
    }
  };

  const getQueues = async () => {
    try {
      const queues = await api.helpers.getAllQueues();
      dispatch({
        type: 'GET_QUEUES',
        payload: {
          queues,
        },
      });
    } catch (err) {
      console.warn('Unable to load queues', err);
    }
  };
  const getNumbers = async () => {
    try {
      const numbers = await api.helpers.getAllNumbers();
      dispatch({
        type: 'GET_NUMBERS',
        payload: {
          numbers,
        },
      });
    } catch (err) {
      console.warn('Unable to load claimed numbers', err);
    }
  };
  const getTimezones = async () => {
    try {
      const timezones = await api.helpers.getAllTimezones();
      dispatch({
        type: 'GET_TIMEZONES',
        payload: {
          timezones,
        },
      });
    } catch (err) {
      console.warn('Unable to load timezones', err);
    }
  };

  const setMsg = (msg: IMessage) => {
    dispatch({ type: 'SET_MESSAGE', payload: { msg } });
  };

  const getEntityAttrValue = (type: ENTITY_TYPES, keyType: string, keyValue: string, returnAttr: string = null) =>
    getEntityAttrValueHelper(state, type, keyType, keyValue, returnAttr);

  return (
    <HolidaysManagementContext.Provider
      value={{
        ...state,
        setMsg,
        getEntityAttrValue,
        listHolidays,
        listHolidayPrompts,
      }}>
      {children}
    </HolidaysManagementContext.Provider>
  );
};

export const useHolidaysContext = () => useContext<HoursManagementContextPropsType>(HolidaysManagementContext);

export default HolidaysManagementContextProvider;
