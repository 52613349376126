import { OpenCheckTypes } from '@voicefoundry-cloud/vf-omp-shared';
import HoursManagementContextPropsType from './HoursManagementContextPropTypes';

const config: HoursManagementContextPropsType = {
  hoursList: [],
  hoursLoaded: false,
  loadingHours: false,
  deletingHours: false,
  queues: [],
  queuesLoaded: false,
  numbers: [],
  numbersLoaded: false,
  timezones: [],
  timezonesLoaded: false,
  error: '',
  msg: undefined,
  associationsObj: {
    [OpenCheckTypes.QUEUE]: [],
    [OpenCheckTypes.DNIS]: [],
    [OpenCheckTypes.CUSTOM]: [],
  },
  addAssociationToDict: (association: { key: string; type: OpenCheckTypes }) => {},
  removeAssociationFromDict: (association: { key: string; type: OpenCheckTypes }) => {},
};

export default config;
