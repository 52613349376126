/* eslint-disable no-unused-vars */
import { String } from 'aws-sdk/clients/acm';
import { AttributeListType, UsernameType } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import Connect, {
  ContactFlow,
  ContactFlowSummary,
  HierarchyGroup,
  HierarchyGroupSummary,
  HierarchyGroupSummaryList,
  HierarchyStructure,
  PhoneNumberSummary,
  PromptSummary,
  QueueId,
  QueueSummary,
  RoutingProfileId,
  RoutingProfileName,
  RoutingProfileQueueConfigSummary,
  RoutingProfileQueueConfigSummaryList,
  RoutingProfileSummary,
  SecurityProfileSummary,
  User,
  UserId,
  UserSummary,
} from 'aws-sdk/clients/connect';

import { AgentConfigurationItem, AgentRealTimeMetricOutgoing } from './agentRealTimeMetric';

export interface MenuPlugin {
  id: string;
  auth: string[];
  title: string;
  messageId: string;
  icon?: string;
  type: 'collapse' | 'item';
  url: string;
  children?: MenuPlugin[];
  route?: {
    key: string;
    path: string;
    src: string;
  };
}
export interface PluginConfiguration {
  plugins: MenuPlugin[];
}

/**
 * Connect Lambda Response Type
 */
export interface AttributeMap {
  [name: string]: string;
}

export interface AttributeVm {
  id: string;
  name: string;
  data: string;
}

export interface ClosureVm extends PermissionsAttr {
  enabled: boolean;
  id: string;
  name: string;
  category?: string;
  queues: TemporalAssociation[];
}

export interface ConnectPrompt {
  Id: string;
  Arn: string;
  Name: string;
}

export interface ConnectQueue {
  Id: string;
  Arn: string;
  Name: string;
  QueueType: string;
}

export interface ContactFlowVm extends PermissionsAttr {
  name: string;
  prompts: PromptVm[];
  attributes: AttributeVm[];
}
export interface ConfigurationSetIE {
  name: string;
  prompts: PromptVm[];
  attributes: AttributeVm[];
}

export interface ClosurePromptVm {
  name: string;
  prompts: PromptVm[];
}

export interface HolidayPromptVm {
  name: string;
  prompts: PromptVm[];
}

export interface GetPromptAudioRequest {
  neural: boolean;
  lang: string;
  ssml: string;
  voiceId: string;
}
export interface HoursSelection {
  key: string;
  name: string;
  openCheckType?: OpenCheckTypes;
}
export interface HoursVm extends PermissionsAttr {
  id: string;

  name: string;

  /**
   * TODO: Not sure how this is used
   */
  queues: { key: string; type: OpenCheckTypes }[];

  timezone: string;

  sundayObserved: boolean;
  sundayStart: string;
  sundayEnd: string;

  mondayObserved: boolean;
  mondayStart: string;
  mondayEnd: string;

  tuesdayObserved: boolean;
  tuesdayStart: string;
  tuesdayEnd: string;

  wednesdayObserved: boolean;
  wednesdayStart: string;
  wednesdayEnd: string;

  thursdayObserved: boolean;
  thursdayStart: string;
  thursdayEnd: string;

  fridayObserved: boolean;
  fridayStart: string;
  fridayEnd: string;

  saturdayObserved: boolean;
  saturdayStart: string;
  saturdayEnd: string;
}

export interface HolidayVm extends PermissionsAttr {
  allDay: boolean;
  date: string;
  endTime: string;

  id: string;
  name: string;
  queues: TemporalAssociation[];

  startTime: string;
  timezone: string;
}

export enum PROMPT_TYPES {
  SSML = 'SSML',
  ARN = 'ARN',
  TEXT = 'TEXT',
  NONE = '',
}
export interface Prompt {
  lang: string;
  value: string;
}

export interface StrictPromptItem {
  lang: string;
  prompt: string;
  type?: PROMPT_TYPES;
}

export interface PromptVm extends PermissionsAttr {
  id: string;
  name: string;
  disabled: boolean;
  data: Prompt[];
}

export interface OpenCheckResponse {
  contactCenterState: ContactCenterStateTypes;
  prompt?: string;
}

export interface Token {
  email: string;
  groups?: string[];
  username: string;
  tenantPolicyData?: CustomUserClaimsData[];
  rolePolicyData?: CustomUserClaimsData;
  primaryTenant?: UMITenantSummary;
  tenancyOn?: boolean;
  tenants?: string[];
  features: string[];
}

export interface Queue {
  name: string;
  prompt: string;
}
export interface TemporalAssociation {
  key: string;
  prompt?: string;
  type: OpenCheckTypes;
}

/**
 * Defines standard cognito groups
 */
export type UserGroup = string;

export interface IClaims {
  email: string;
  groups: UserGroup[];
  username: UsernameType;
  features: string[];
  tenancyEnabled?: boolean;
  tenancyOn?: boolean;
  tenants: UMITenantSummary[];
}

export type IRole = string;
export type IUsername = string;
export type IGroupName = string;
export type IAdminUserGroupId = string;
export interface IUser extends IClaims {
  role: IRole;
}
export interface IAdminUserGroup extends IAdminUserGroupData {
  id?: IAdminUserGroupId;
  name: IGroupName;
  users?: UsernameType[];
}

export interface IRoutingProfileData {
  id: RoutingProfileId;
  name: RoutingProfileName;
  queues: RoutingProfileQueueConfigSummaryList;
}
export interface IAdminConnectData {
  agentData: {
    list: User[];
    dict?: {
      [name: string]: User;
    };
  };
  routingProfileData: {
    list: IRoutingProfileData[];
    dict?: {
      [name: string]: IRoutingProfileData;
    };
  };
  queueData: {
    list: QueueSummary[];
    dict?: {
      [name: string]: QueueSummary;
    };
  };
  userHierarchyStructure: HierarchyStructure;
  userHierarchyGroups: HierarchyGroupSummaryList;
}
export interface IAdminUserGroupData {
  agents: UserId[];
  queues: QueueId[];
  routingProfiles: RoutingProfileId[];
}

export interface IUpdateAdminUserGroupUsers {
  added: UsernameType[];
  addedFailed?: UsernameType[];
  removed: UsernameType[];
  removedFailed?: UsernameType[];
}

export interface IUpdateAdminUserGroupUsersResponse {
  addedSuccess: UsernameType[];
  addedFailed: UsernameType[];
  removedSuccess: UsernameType[];
  removedFailed: UsernameType[];
}

export interface IFlowIdDict {
  [name: string]: string;
}
export interface IQueueArnDict {
  [name: string]: string;
}
export interface IPathMappingDict {
  queues: IQueueArnDict;
  flows: IFlowIdDict;
}
export interface IMappingDict {
  [name: string]: string;
}
export interface IMappingSyncDataItem {
  [name: string]: IMappingSyncDataItemData;
}
export interface IMappingSyncDataItemData {
  local: string;
  mapped: string;
  dateMapped: number;
}
export type IMappingSyncDataItemTuple = [string, IMappingSyncDataItemData];
export type IMappingSyncDataTupleList = IMappingSyncDataItemTuple[];

export interface IMappingSyncData extends IMappingSyncFlows {
  queues: IMappingSyncDataItem;
  promptArns: IMappingSyncDataItem;
  lambdaArns: IMappingSyncLambdaArns;
}

export interface IMappingSyncFlows {
  flows: IMappingSyncDataItem;
  flowArns: IMappingSyncDataItem;
}
export interface IMappingSyncLambdaArns {
  availableLambdas: IAvailableIncomingLambdaList | null;
  lambdaArns: IMappingSyncDataItem;
}
export type IAvailableIncomingLambdaList = IAvailableIncomingLambda[];
export interface IAvailableIncomingLambda {
  id?: string;
  name: string;
  arn: string;
}

export interface IFindReplaceResponse {
  newString: string;
  matches: boolean;
  data?: IMatchingResponse;
}
export interface IFindMappedItemResponse {
  matches: boolean;
  data?: IMatchingResponse;
}
export interface IMatchingResponse {
  name: string;
  matches: IMatchingResponseMatches;
}
export interface IMatchingResponseMatches {
  replacedItem: string;
  replacedWith: string;
  amount: number;
}
export interface IConfigSetSyncResponse {
  success: boolean;
  matchingData?: IConfigSetSyncResponseData;
}
export interface IConfigSetSyncResponseData {
  [name: string]: {
    [name: string]: {
      replacedWith: string;
      amount: number;
    };
  };
}
export interface SyncContactFlowInitializationParams {
  contactFlows: { name: string; id: string }[];
}
export interface ImportContactFlowSyncInitializationParams {
  contactFlows: ContactFlow[];
}
export interface ContactFlowExportParams {
  contactFlows: { name: string; id: string }[];
}
export interface ContactFlowImportParams {
  updateExisting: boolean;
  contactFlows: ContactFlowImport[];
}

export type SyncInitializationResponse = SyncInitializationResponseItem[];

export type SyncConfigSetInitializationResponse = SyncConfigSetInitializationResponseItem[];

export interface SyncInitializationResponseItem {
  id?: string;
  name: string;
  flowId?: string;
  type?: string;
  foundName: string;
  foundItem: string;
  replacedWith: string;
  amount: number;
  status?: string;
  message?: string;
}

export interface ContactFlowExportResponse {
  contactFlows?: ContactFlow[];
  ok: boolean;
  message?: string;
}
export interface SyncConfigSetInitializationResponseItem {
  id?: string;
  name: string;
  foundName?: string;
  foundItem?: string;
  replacedWith?: string;
  amount: number;
  status?: string;
  message?: string;
}

export interface SyncReplaceSelectedMatchesParamsItem {
  id?: string;
  flowId?: string;
  flowName: string;
  selectedMatches: SyncInitializationResponseItem[];
}
export interface SyncReplaceSelectedMatchesForImportParamsItem {
  id?: string;
  flowId?: string;
  flowName: string;
  contactFlow?: ContactFlow;
  selectedMatches: SyncInitializationResponseItem[];
}
export interface SyncReplaceSelectedMatchesParams {
  [name: string]: SyncReplaceSelectedMatchesParamsItem;
}
export interface SyncReplaceSelectedMatchesFromImportedFlows {
  [name: string]: ContactFlow;
}
export interface ReplaceSelectedMatchesFromImportedParams {
  [name: string]: SyncReplaceSelectedMatchesForImportParamsItem;
}
export type SyncReplaceSelectedMatchesResponse = SyncReplaceSelectedMatchesResponseItem[];

export interface SyncReplaceSelectedMatchesResponseItem extends SyncInitializationResponseItem {
  data?: any;
  contactFlow?: ContactFlow;
  numOfItems?: number;
  totalReplacements?: number;
}

// export interface SyncConfigSetData {
//   id?: string;
//   name: string;
//   prompts: number;
//   attributes: number;
// }

//------------------------
// Sync Refactor Interfaces
//------------------------

export interface SyncContactFlowItem extends ContactFlowSummary {
  contactFlow?: ContactFlow;
  id?: string;
  status?: string;
  message?: string;
}
export interface IConfigSet {
  name: string;
  prompts?: PromptVm[];
  attributes?: AttributeVm[];
}
export interface SyncConfigSetItem extends IConfigSet {
  configSet?: IConfigSet;
  id?: string;
  status?: string;
  message?: string;
}
export type SyncConfigSetDataList = SyncConfigSetItem[];
export interface SyncContactFlowValidItem extends SyncContactFlowItem {
  replaceData?: SyncContactFlowReplaceData;
}
export interface SyncContactFlowMatchingItem extends SyncContactFlowValidItem {
  matchingData?: SyncContactFlowMatchingData;
}
export interface SyncConfigSetValidItem extends SyncConfigSetItem {
  replaceData?: SyncConfigSetReplaceData;
}
export interface SyncConfigSetMatchingItem extends SyncConfigSetValidItem {
  matchingData?: SyncConfigSetMatchingData;
}
export interface SyncContactFlowMatchingData {
  foundName: string;
  foundItem: string;
  foundType: string;
  replacedWith: string;
  amount: number;
}
export interface SyncConfigSetMatchingData {
  foundName: string;
  foundItem: string;
  foundType: string;
  replacedWith: string;
  amount: number;
}
export interface SyncContactFlowReplaceData {
  numOfItems?: number;
  totalReplacements?: number;
}
export interface SyncConfigSetReplaceData {
  numOfItems?: number;
  totalReplacements?: number;
}
//---
export type ExistingContactFlowSyncInitializationRequest = ContactFlowSummary[];
export type ImportContactFlowSyncInitializationRequest = SyncContactFlowItem[];
export type ImportContactFlowSyncInitializationResponse = SyncContactFlowMatchingItem[];
export type ExistingContactFlowSyncInitializationResponse = SyncContactFlowMatchingItem[];

//---

export type ExistingConfigSetSyncInitializationRequest = SyncConfigSetItem[];
export type ImportConfigSetSyncInitializationRequest = SyncConfigSetItem[];
export type ImportConfigSetSyncInitializationResponse = SyncConfigSetMatchingItem[];
export type ExistingConfigSetSyncInitializationResponse = SyncConfigSetMatchingItem[];

export interface FindReplaceableImportFlowPathsResponse {
  matches: boolean;
  data?: SyncContactFlowMatchingItem;
}
export interface FindReplaceableExistingFlowPathsResponse {
  matches: boolean;
  data?: SyncContactFlowMatchingItem;
}
export interface FindReplaceableConfigSetPathsResponse {
  matches: boolean;
  data?: SyncConfigSetMatchingItem;
}

export interface SyncContactFlowMatchingReplaceItem extends SyncContactFlowMatchingItem {
  selectedMatches: SyncContactFlowMatchingData[];
}
export interface SyncConfigSetMatchingReplaceItem extends SyncConfigSetMatchingItem {
  selectedMatches: SyncConfigSetMatchingData[];
}
export interface SyncContactFlowMatchingReplaceRequest {
  [name: string]: SyncContactFlowMatchingReplaceItem;
}
export type SyncContactFlowMatchingReplaceResponse = SyncContactFlowValidItem[];

export interface SyncConfigSetMatchingReplaceRequest {
  [name: string]: SyncConfigSetMatchingReplaceItem;
}
export type SyncConfigSetMatchingReplaceResponse = SyncConfigSetValidItem[];
export interface SyncReplaceSelectedMatchesFunctionParams extends SyncContactFlowMatchingData {
  flowJson: string;
}
export interface ContactFlowImportRequest {
  updateExisting: boolean;
  contactFlows: ContactFlowImport[];
}
export interface ContactFlowImport {
  importFlow: ContactFlow;
  existingFlow?: ContactFlow;
}
export interface ContactFlowImportResponse {
  ok: boolean;
  message?: string;
  contactFlows?: ContactFlowImportResponseItem[];
}
export interface ContactFlowImportResponseItem extends ContactFlow {
  status: string;
  message?: string;
  error?: {
    code: string;
    message: string;
  };
}
export interface SyncConfigSetInitializationParams {
  configSets: { name: string; id: string }[];
}
export interface SyncInitializationFindPathResponse {
  matches: boolean;
  data?: SyncInitializationResponseItem;
}
export interface ConfigSetImportRequest {
  updateExisting: boolean;
  configSets: ConfigSetImport[];
}
export interface ConfigSetImport {
  importSet: IConfigSet;
  existingSet?: IConfigSet;
}
export interface ConfigSetImportResponse {
  ok: boolean;
  message?: string;
  configSets?: ConfigSetImportResponseItem[];
}
export interface ConfigSetImportResponseItem extends IConfigSet {
  status: string;
  message?: string;
  error?: {
    code: string;
    message: string;
  };
}

/**
 * Generic Action behaviors used with Menus and Entry flows
 * @description
 */
export enum FlowActionType {
  TRANSFER_TO_QUEUE = 'TRANSFER_TO_QUEUE',
  TRANSFER_TO_EXTERNAL_NUMBER = 'TRANSFER_TO_EXTERNAL_NUMBER',
  TRANSFER_TO_FLOW = 'TRANSFER_TO_FLOW',
  TRANSFER_TO_MENU = 'TRANSFER_TO_MENU',
  PROMPT_THEN_DISCONNECT = 'PROMPT_THEN_DISCONNECT',
}

export enum YES_NO {
  YES = 'YES',
  NO = 'NO',
}
export enum INPUT_STATUS {
  VALID = 'VALID',
  INVALID = 'INVALID',
}
export enum CHECK_BY_TYPE {
  UNDEFINED = 'UNDEFINED',
  FALSY = 'FALSY',
  EMPTY = 'EMPTY',
}

/**
 * Response objects should be used within the contact flows in connect.
 */
export class BaseActionResponse<T> {
  inputStatus?: INPUT_STATUS;
  actionType?: T;
  menuActionType?: T;
  journeyOverride?: string;
  hasJourneyOverride?: YES_NO;
}

export class TransferToQueueActionResponse<T> extends BaseActionResponse<T> {
  actionType?: T;
  queueId?: string;
  setWorkingQueue?: YES_NO;
  isPriority?: YES_NO;
  preTransferPrompt?: string;
  preTransferPromptType?: PROMPT_TYPES;
  hasPreTransferPrompt?: YES_NO;
  customerQueueFlowId?: string;
  queueTreatmentKey?: string;
  useQueueTreatmentKey?: YES_NO;
  setCustomerQueueFlow?: YES_NO;
}

export interface TransferToMenuActionResponse<T> extends BaseActionResponse<T> {
  menuKey?: string;
  setLang?: YES_NO;
  lang?: string;
  preTransferPrompt?: string;
  preTransferPromptType?: PROMPT_TYPES;
  hasPreTransferPrompt?: YES_NO;
}

export interface TransferToExternalNumberActionResponse<T> extends BaseActionResponse<T> {
  externalNumber?: string;
  extension: string;
  hasExtension: YES_NO;
  preTransferPrompt?: string;
  preTransferPromptType?: PROMPT_TYPES;
  hasPreTransferPrompt?: YES_NO;
}

export interface TransferToFlowActionResponse<T> extends BaseActionResponse<T> {
  flowId?: string;
}

export interface PromptThenDisconnectResponse<T> extends BaseActionResponse<T> {
  prompt?: string;
  promptType?: PROMPT_TYPES;
  hasPrompt?: YES_NO;
}
//------------------------
// Menu Manager Models
//------------------------

export type MenuPromptItem = StrictPromptItem;

export interface GetMenuDataResponse {
  journeyOverride?: string;
  hasJourneyOverride?: YES_NO;
  retryCount: number;
  greeting?: string;
  greetingType?: PROMPT_TYPES;
  hasGreeting?: YES_NO;
  promptIntro: string;
  promptIntroType: PROMPT_TYPES;
  validOptions: string;
  isSimpleMenu: YES_NO;
  terminatingKeypress: string;
}
export type MenuPrompt = MenuPromptItem[];

export type MenuList = Menu[];

export class Menu {
  id?: string;
  key: string;
  description?: string;
  journeyOverride?: string;
  defaultMenuOption: MenuOption;
  terminatingKeypress: string = '#';
  repeatMenuOption: MenuOption = {
    dtmfOption: '*',
    action: new RepeatMenuAction(),
  };
  menuOptions: MenuOption[];
  retryCount?: number;
  isEnabled: boolean;

  /**
   * @description un-interruptable message played before going into the menu
   */
  greeting?: MenuPrompt;
  /**
   * @description Interruptable message to be played before listing the menu options
   */
  promptIntro: MenuPrompt;
  /**
   * @description Infers the valid menu options from the actual list of options
   */
  getValidOptions?(): string {
    if (!this.menuOptions || !this.menuOptions.length) throw new Error('Invalid menu option configuration for');
    const arr = this.menuOptions.map(opt => opt.dtmfOption);
    return arr.join(',');
  }
}

export enum MenuActionType {
  TRANSFER_TO_QUEUE = 'TRANSFER_TO_QUEUE',
  TRANSFER_TO_EXTERNAL_NUMBER = 'TRANSFER_TO_EXTERNAL_NUMBER',
  TRANSFER_TO_FLOW = 'TRANSFER_TO_FLOW',
  TRANSFER_TO_MENU = 'TRANSFER_TO_MENU',
  RETRY_LOOP = 'RETRY_LOOP',
  REPEAT_MENU = 'REPEAT_MENU',
}

export class MenuOption {
  dtmfOption: string;
  action: MenuOptionActionType;
}

export class DefaultMenuOption extends MenuOption {
  dtmfOption: 'Timeout';
  action: DefaultMenuOptionActionType;
}

export interface RetryLoopActionResponse<T> extends BaseActionResponse<T> {
  menuActionType?: T;
  retryPrompt?: string;
  hasRetryPrompt?: YES_NO;
  retryPromptType?: PROMPT_TYPES;
}
export interface RepeatMenuActionResponse<T> extends BaseActionResponse<T> {
  menuActionType?: T;
}

export type MenuActionResponse =
  | TransferToQueueActionResponse<MenuActionType.TRANSFER_TO_QUEUE>
  | TransferToExternalNumberActionResponse<MenuActionType.TRANSFER_TO_EXTERNAL_NUMBER>
  | TransferToFlowActionResponse<MenuActionType.TRANSFER_TO_FLOW>
  | TransferToMenuActionResponse<MenuActionType.TRANSFER_TO_MENU>
  | RetryLoopActionResponse<MenuActionType.RETRY_LOOP>
  | RepeatMenuActionResponse<MenuActionType.REPEAT_MENU>;

export type MenuOptionActionType =
  | TransferToQueueAction
  | TransferToExternalNumberAction
  | TransferToFlowAction
  | TransferToMenuAction
  | RetryLoopMenuAction
  | RepeatMenuAction;

export type DefaultMenuOptionActionType =
  | TransferToQueueAction
  | TransferToExternalNumberAction
  | TransferToFlowAction
  | TransferToMenuAction
  | RetryLoopMenuAction;

export class MenuOptionAction {
  menuActionType: MenuActionType;
  journeyOverride?: string;
  constructor(key: MenuActionType) {
    this.menuActionType = key;
  }
}
/**
 * @description Transfers the caller to a specific queue
 */
export class TransferToQueueAction extends MenuOptionAction {
  constructor() {
    super(MenuActionType.TRANSFER_TO_QUEUE);
  }
  /**
   * If this is NOT set, we will want to use the working Queue
   */
  queueId?: string;
  /**
   * @description Whether the caller should be placed at the top of the queue or not
   */
  isPriority?: boolean = false;
  /**
   * @description The prompt to be played before transferring a caller
   */
  preTransferPrompt?: MenuPrompt;
  queueTreatmentKey?: string;
  useQueueTreatmentKey: boolean = false;
  customerQueueFlowId?: string;
}

/**
 * @description Transfers the caller to a specific queue for an agent interaction (or callback/VM logic)
 */
export class TransferToExternalNumberAction extends MenuOptionAction {
  constructor() {
    super(MenuActionType.TRANSFER_TO_EXTERNAL_NUMBER);
  }
  externalNumber: string;
  /**
   * @description The prompt to be played before transferring a caller
   */
  preTransferPrompt?: MenuPrompt;
  extension?: string;
}

/**
 * @description Transfers the caller to a specific Contact Flow to continue the engagement
 */
export class TransferToFlowAction extends MenuOptionAction {
  constructor() {
    super(MenuActionType.TRANSFER_TO_FLOW);
  }
  flowId: string;
}

/**
 * @description Transfers the caller to a sub-menu
 */
export class TransferToMenuAction extends MenuOptionAction {
  constructor() {
    super(MenuActionType.TRANSFER_TO_MENU);
  }
  menuKey: string;
  lang?: string = '';
  preTransferPrompt?: MenuPrompt;
  previousMenuKey?: string;
}

/**
 * @description Transfers the caller to a sub-menu
 */
export class RetryLoopMenuAction extends MenuOptionAction {
  constructor() {
    super(MenuActionType.RETRY_LOOP);
  }
  retryPrompt?: MenuPrompt;
}

/**
 * @description Transfers the caller to a sub-menu
 */
export class RepeatMenuAction extends MenuOptionAction {
  constructor() {
    super(MenuActionType.REPEAT_MENU);
  }
}

//------------------------
// Entry Manager Models
//------------------------

/**
 * Get Entry Flow Lambda
 * @param type would be entry in this use case
 * @param DNIS phone number being called (could be inferred)
 *
 * @description
 * 1) Call OMP app fn to get Queue (return all data)
 * 2) Using queue name lookup hours
 * 3) Check state of contact center
 * 4) Return behavior obj based on the provided state
 *
 * @returns GetEntryDataResponse
 */

export type FlowDataList = FlowData[];
export type FlowDataPromptItem = StrictPromptItem;
export type FlowDataPrompt = FlowDataPromptItem[];

export enum ContactCenterStateTypes {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  HOLIDAY = 'HOLIDAY',
  CLOSURE = 'CLOSURE',
}
/**
 * Open Check Selection Types
 */
export enum OpenCheckTypes {
  QUEUE = 'QUEUE',
  DNIS = 'DNIS',
  CUSTOM = 'CUSTOM',
}
/**
 * Used by OMP frontend
 */
export type FlowData = EntryFlowData | QueueFlowData;

export class EntryFlowData {
  id?: string; // Would be flow type? (entry)
  key: string; // Would be DNIS
  description?: string;
  defaultJourneyOverride?: string;
  isEnabled: boolean;
  openCheckKey: string;
  openCheckType: OpenCheckTypes;
  defaultQueueId: string;
  defaultLang: string;
  useDefaultQueueTreatmentKey: boolean = false;
  defaultQueueTreatmentKey?: string;
  defaultCustomerQueueFlowId: string;
  defaultWhisperFlow: string;
  defaultHoldFlow: string;
  defaultDisconnectFlow: string;
  errorMsg?: FlowDataPrompt;
  invalidInputMsg?: FlowDataPrompt;
  afterHoursMsg: FlowDataPrompt;
  [ContactCenterStateTypes.OPEN]: ContactFlowAction;
  [ContactCenterStateTypes.CLOSED]: ContactFlowAction;
  [ContactCenterStateTypes.CLOSURE]: ContactFlowAction;
  [ContactCenterStateTypes.HOLIDAY]: ContactFlowAction;
}

export type GetEntryDataResponse = GetFlowDataResponse & {
  contactCenterState: ContactCenterStateTypes;
  defaultJourneyOverride?: string;
  hasDefaultJourneyOverride?: YES_NO;
  defaultQueueId: string;
  setDefaultWorkingQueue: YES_NO;
  defaultCustomerQueueFlowId: string;
  defaultQueueTreatmentKey: string;
  setDefaultCustomerQueueFlow: YES_NO;
  useDefaultQueueTreatmentKey: YES_NO;
  defaultWhisperFlowId: string;
  setDefaultWhisperFlow: YES_NO;
  defaultHoldFlowId: string;
  setDefaultHoldFlow: YES_NO;
  defaultDisconnectFlowId: string;
  setDefaultDisconnectFlow: YES_NO;
  invalidInputMsg?: string;
  hasInvalidInputMsg?: YES_NO;
  invalidInputMsgType?: PROMPT_TYPES;
  errorMsg?: string;
  hasErrorMsg?: YES_NO;
  errorMsgType?: PROMPT_TYPES;
  afterHoursMsg?: string;
  hasAfterHoursMsg?: YES_NO;
  afterHoursMsgType?: PROMPT_TYPES;
  lang: string;
};

/**
 * @description Integrated in connect to determine contact flow behavior
 */

export type GetFlowDataResponse =
  | TransferToQueueActionResponse<FlowActionType.TRANSFER_TO_QUEUE>
  | TransferToExternalNumberActionResponse<FlowActionType.TRANSFER_TO_EXTERNAL_NUMBER>
  | TransferToFlowActionResponse<FlowActionType.TRANSFER_TO_FLOW>
  | TransferToMenuActionResponse<FlowActionType.TRANSFER_TO_MENU>
  | PromptThenDisconnectResponse<FlowActionType.PROMPT_THEN_DISCONNECT>;

export class ContactFlowAction {
  actionRef: string; // Used to reference this action b/c we store this obj nested
  contactFlowActionType: FlowActionType;
  journeyOverride?: string;
  constructor(key: FlowActionType) {
    this.contactFlowActionType = key;
  }
}
/**
 * @description Transfers the caller to a specific queue
 */
export class TransferToQueueContactFlowContactFlowAction extends ContactFlowAction {
  constructor(input?: { [String in keyof TransferToQueueContactFlowContactFlowAction]?: any }) {
    super(FlowActionType.TRANSFER_TO_QUEUE);
    if (input) Object.assign(this, input);
  }
  /**
   * If this is NOT set, we will want to use the working Queue
   */
  queueId?: string;
  /**
   * @description Whether the caller should be placed at the top of the queue or not
   */
  isPriority?: boolean = false;
  /**
   * @description The prompt to be played before transferring a caller
   */
  preTransferPrompt?: MenuPrompt;
  queueTreatmentKey?: string;
  useQueueTreatmentKey: boolean = false;
  customerQueueFlowId?: string;
}

/**
 * @description Transfers the caller to a specific queue for an agent interaction (or callback/VM logic)
 */
export class TransferToExternalNumberContactFlowAction extends ContactFlowAction {
  constructor(input?: { [String in keyof TransferToExternalNumberContactFlowAction]?: any }) {
    super(FlowActionType.TRANSFER_TO_EXTERNAL_NUMBER);
    if (input) Object.assign(this, input);
  }
  externalNumber: string;
  /**
   * @description The prompt to be played before transferring a caller
   */
  preTransferPrompt?: MenuPrompt;
  extension?: string;
}

/**
 * @description Transfers the caller to a specific Contact Flow to continue the engagement
 */
export class TransferToFlowContactFlowAction extends ContactFlowAction {
  constructor(input?: { [String in keyof TransferToFlowContactFlowAction]?: any }) {
    super(FlowActionType.TRANSFER_TO_FLOW);
    if (input) Object.assign(this, input);
  }
  flowId: string;
}

/**
 * @description Transfers the caller to a sub-menu
 */
export class TransferToMenuContactFlowAction extends ContactFlowAction {
  constructor(input?: { [String in keyof TransferToMenuContactFlowAction]?: any }) {
    super(FlowActionType.TRANSFER_TO_MENU);
    if (input) Object.assign(this, input);
  }
  menuKey: string;
  lang?: string = '';
  preTransferPrompt?: MenuPrompt;
}

/** Prompt Transfers the caller to a sub-menu
 */
export class PromptThenDisconnectFlowAction extends ContactFlowAction {
  constructor(input?: { [String in keyof PromptThenDisconnectFlowAction]?: any }) {
    super(FlowActionType.PROMPT_THEN_DISCONNECT);
    if (input) Object.assign(this, input);
  }
  prompt: MenuPrompt;
  promptType: PROMPT_TYPES;
}

//------------------------
// Queue Flow Manager Models
//------------------------

/**
* 
* id (queue-flow)
* key / queueFlowKey
* lang 
* description
* journeyOverride
* isPriority
* priorityLevel (default 1)
* loopInitializationPrompt
* loopInitializationPromptType
* ActionType
* interruptSetting
* holdMusicId
* holdMusicName
* queueFlowId


* interrupt flows
* --------------------* 
* BASIC PROMPT
* -------------------
* interruptMsg
* interruptPMsgType
* 
* CALLBACK OFFER
* ------------------
* callbackOfferMsg
* callbackOfferMsgType
* callingPhoneMsg
* callingPhoneMsgType
* callbackConfirmMsg
* callbackConfirmMsgType
* enterCallbackPhoneMsg
* enterCallbackPhoneMsgType
* confirmNewCallbackPhoneMsg
* confirmNewCallbackPhoneMsgType
* thankyouMsg
* thankyouMsgType
* 
* VOICEMAIL OFFER
* ---------------------
* voicemailOfferMsg
* voicemailOfferMsgType
* mailboxExtension
* voicemailThanksMsg
* voicemailThanksMsgType
* 
* CALLBACK VOICEMAIL OFFER
* ------------------
* callbackOrVoicemailOfferMsg
* callbackOrVoicemailOfferMsgType
* callingPhoneMsg
* callingPhoneMsgType
* callbackConfirmMsg
* callbackConfirmMsgType
* enterCallbackPhoneMsg
* enterCallbackPhoneMsgType
* confirmNewCallbackPhoneMsg
* confirmNewCallbackPhoneMsgType
* thankyouMsg
* thankyouMsgType
* mailboxExtension
* voicemailThanksMsg
* voicemailThanksMsgType
* 
*/
export type CallerPrompt = StrictPromptItem[];

export enum QueueFlowTypes {
  BASIC_HOLD = 'BASIC_HOLD',
  INTERRUPT_ACTION = 'INTERRUPT_ACTION',
}
export enum QueueFlowActionTypes {
  BASIC_HOLD = 'BASIC_HOLD',
  BASIC_PROMPT = 'BASIC_PROMPT',
  CALLBACK_OFFER = 'CALLBACK_OFFER',
  VOICEMAIL_OFFER = 'VOICEMAIL_OFFER',
  CALLBACK_VOICEMAIL_OFFER = 'CALLBACK_VOICEMAIL_OFFER',
  // WAIT_TIME = 'WAIT_TIME',
  // WAIT_TIME_CALLBACK = 'WAIT_TIME_CALLBACK',
}

/**
 * Matches a QFE flow to a prompt and interrupt
 */
export interface QueueFlowMapper {
  default: {
    INTERRUPT_ACTION: {
      '15_SECONDS': Record<string, string>;
      '30_SECONDS': Record<string, string>;
      '60_SECONDS': Record<string, string>;
      '90_SECONDS': Record<string, string>;
      '120_SECONDS': Record<string, string>;
      '180_SECONDS': Record<string, string>;
    };
    BASIC_HOLD: Record<string, string>;
  };
  custom: {
    INTERRUPT_ACTION: unknown;
  };
}
export interface GetQueueFlowDataResponse {
  id?: string;
  key?: string;
  lang: string;
  journeyOverride?: string;
  hasJourneyOverride: YES_NO;
  isPriority?: YES_NO;
  overridePriority: YES_NO;
  priorityLevel: number;
  loopInitializationMsg?: string;
  hasLoopInitializationMsg: YES_NO;
  loopInitializationMsgType?: PROMPT_TYPES;
  holdMusicId?: string;
  loopActionType?: QueueFlowActionTypes;
  qfe_journeyOverride?: string;
  has_qfe_journeyOverride?: YES_NO;
  hasServerError?: YES_NO;
  serverErrorType?: QueueFlowServerErrorTypes;
  serverErrorMsg?: string;

  interruptMsg?: string;
  interruptMsgType?: PROMPT_TYPES;

  callbackOfferMsg?: string;
  callbackOfferMsgType?: PROMPT_TYPES;
  confirmNewCallbackPhoneMsg?: string;
  confirmNewCallbackPhoneMsgType?: PROMPT_TYPES;
  invalidPhoneMsg?: string;
  invalidPhoneMsgType?: PROMPT_TYPES;
  callingPhoneMsg?: string;
  callingPhoneMsgType?: PROMPT_TYPES;
  callbackConfirmMsg?: string;
  callbackConfirmMsgType?: PROMPT_TYPES;
  thankyouMsg?: string;
  thankyouMsgType?: PROMPT_TYPES;
  enterCallbackPhoneMsg?: string;
  enterCallbackPhoneMsgType?: PROMPT_TYPES;

  voicemailOfferMsg?: string;
  voicemailOfferMsgType?: PROMPT_TYPES;
  voicemailThanksMsg?: string;
  voicemailThanksMsgType?: PROMPT_TYPES;
  mailboxExtension?: string;

  callbackOrVoicemailOfferMsg?: string;
  callbackOrVoicemailOfferMsgType?: PROMPT_TYPES;
}

export enum QueueFlowServerErrorTypes {
  NO_PROMPT_CONFIGURED_FOR_LANGUAGE = 'NO_PROMPT_CONFIGURED_FOR_LANGUAGE',
  MISSING_REQUIRED_ATTRIBUTES = 'MISSING_REQUIRED_ATTRIBUTES',
}
export class QueueFlowServerErrorDefaultMsgs {
  defaultMsgs = {
    [QueueFlowServerErrorTypes.NO_PROMPT_CONFIGURED_FOR_LANGUAGE]: 'No prompt configured for language',
    [QueueFlowServerErrorTypes.MISSING_REQUIRED_ATTRIBUTES]: 'Missing required attributes',
  };
}

export type GetQueueFlowDataActionResponse =
  | BasicHoldQFEResponse
  | BasicPromptQFEResponse
  | CallbackOfferQFEResponse
  | VoicemailOfferQFEResponse
  | CallbackOrVoicemailOfferQFEResponse;

export class BaseQFEActionResponse {
  actionType: QueueFlowActionTypes;
  qfe_journeyOverride: string;
  has_qfe_journeyOverride: YES_NO;
  hasServerError?: YES_NO;
  serverErrorType?: QueueFlowServerErrorTypes;
  serverErrorMsg?: string;
}
export interface BasicHoldQFEResponse extends BaseQFEActionResponse {}
export interface BasicPromptQFEResponse extends BaseQFEActionResponse {
  interruptMsg: string;
  interruptMsgType: PROMPT_TYPES;
}
export interface CallbackOfferQFEResponse extends BaseQFEActionResponse {
  callbackOfferMsg: string;
  callbackOfferMsgType: PROMPT_TYPES;
  confirmNewCallbackPhoneMsg: string;
  confirmNewCallbackPhoneMsgType: PROMPT_TYPES;
  invalidPhoneMsg: string;
  invalidPhoneMsgType: PROMPT_TYPES;
  callingPhoneMsg: string;
  callingPhoneMsgType: PROMPT_TYPES;
  callbackConfirmMsg: string;
  callbackConfirmMsgType: PROMPT_TYPES;
  thankyouMsg: string;
  thankyouMsgType: PROMPT_TYPES;
  enterCallbackPhoneMsg: string;
  enterCallbackPhoneMsgType: PROMPT_TYPES;
}
export interface VoicemailOfferQFEResponse extends BaseQFEActionResponse {
  voicemailOfferMsg: string;
  voicemailOfferMsgType: PROMPT_TYPES;
  voicemailThanksMsg: string;
  voicemailThanksMsgType: PROMPT_TYPES;
  mailboxExtension: string;
}
export interface CallbackOrVoicemailOfferQFEResponse extends BaseQFEActionResponse {
  callbackOrVoicemailOfferMsg: string;
  callbackOrVoicemailOfferMsgType: PROMPT_TYPES;
  confirmNewCallbackPhoneMsg: string;
  confirmNewCallbackPhoneMsgType: PROMPT_TYPES;
  invalidPhoneMsg: string;
  invalidPhoneMsgType: PROMPT_TYPES;
  callingPhoneMsg: string;
  callingPhoneMsgType: PROMPT_TYPES;
  callbackConfirmMsg: string;
  callbackConfirmMsgType: PROMPT_TYPES;
  thankyouMsg: string;
  thankyouMsgType: PROMPT_TYPES;
  enterCallbackPhoneMsg: string;
  enterCallbackPhoneMsgType: PROMPT_TYPES;
  voicemailThanksMsg: string;
  voicemailThanksMsgType: PROMPT_TYPES;
  mailboxExtension: string;
}

export class QueueFlowData {
  id?: string;
  key: string;
  lang: string;
  description?: string;
  interruptSetting: number;
  isEnabled: boolean;
  journeyOverride?: string;
  isPriority: boolean;
  priorityLevel: number;
  loopInitializationMsg: FlowDataPrompt;
  holdMusicId: string;
  holdMusicName: string;
  queueFlowId: string;
  queueFlowAction: QueueFlowAction;
}

export class QueueFlowAction {
  qfe_journeyOverride: string;
  mailboxExtension?: string;
  constructor(readonly actionType: QueueFlowActionTypes) {}
}
export class BasicHoldQueueFlowAction extends QueueFlowAction {
  constructor(input?: { [String in keyof BasicHoldQueueFlowAction]?: any }) {
    super(QueueFlowActionTypes.BASIC_HOLD);
    if (input) Object.assign(this, input);
  }
}

export class BasicPromptQueueFlowAction extends QueueFlowAction {
  interruptMsg: FlowDataPrompt;
  constructor(input?: { [String in keyof BasicPromptQueueFlowAction]?: any }) {
    super(QueueFlowActionTypes.BASIC_PROMPT);
    if (input) Object.assign(this, input);
  }
}

export class CallbackOfferQueueFlowAction extends QueueFlowAction {
  callbackOfferMsg: FlowDataPrompt;
  confirmNewCallbackPhoneMsg: FlowDataPrompt;
  invalidPhoneMsg: FlowDataPrompt;
  callingPhoneMsg: FlowDataPrompt;
  callbackConfirmMsg: FlowDataPrompt;
  thankyouMsg: FlowDataPrompt;
  enterCallbackPhoneMsg: FlowDataPrompt;
  constructor(input?: {
    [String in keyof CallbackOfferQueueFlowAction]?: any;
  }) {
    super(QueueFlowActionTypes.CALLBACK_OFFER);
    if (input) Object.assign(this, input);
  }
}

export class VoicemailOfferQueueFlowAction extends QueueFlowAction {
  voicemailOfferMsg: FlowDataPrompt;
  voicemailThanksMsg: FlowDataPrompt;
  mailboxExtension: string;
  constructor(input?: {
    [String in keyof VoicemailOfferQueueFlowAction]?: any;
  }) {
    super(QueueFlowActionTypes.VOICEMAIL_OFFER);
    if (input) Object.assign(this, input);
  }
}
export class CallbackOrVoicemailOfferQueueFlowAction extends QueueFlowAction {
  callbackOrVoicemailOfferMsg: FlowDataPrompt;
  confirmNewCallbackPhoneMsg?: FlowDataPrompt;
  invalidPhoneMsg?: FlowDataPrompt;
  callingPhoneMsg?: FlowDataPrompt;
  callbackConfirmMsg?: FlowDataPrompt;
  thankyouMsg?: FlowDataPrompt;
  enterCallbackPhoneMsg?: FlowDataPrompt;
  voicemailThanksMsg?: FlowDataPrompt;
  mailboxExtension?: string;
  constructor(input?: { [String in keyof CallbackOrVoicemailOfferQueueFlowAction]?: any }) {
    super(QueueFlowActionTypes.CALLBACK_VOICEMAIL_OFFER);
    if (input) Object.assign(this, input);
  }
}
// export class WaitTimeQueueFlowAction extends QueueFlowAction {
//   constructor(input?: { [String in keyof WaitTimeQueueFlowAction]?: any }) {
//     super(QueueFlowActionTypes.WAIT_TIME);
//     if (input) Object.assign(this, input);
//   }
// }

// export class WaitTimeCallbackQueueFlowAction extends QueueFlowAction {
//   constructor(input?: { [String in keyof WaitTimeCallbackQueueFlowAction]?: any }) {
//     super(QueueFlowActionTypes.WAIT_TIME_CALLBACK);
//     if (input) Object.assign(this, input);
//   }
// }

/**
 * User Management Models
 */

export type UMITenantSummaryList = UMITenantSummary[];
export type UMITenantList = UMTenantItem[];
export interface UMITenantSummary {
  name?: string;
  key: string;
  primary?: boolean;
  roles?: UMIRoleSummary;
  users?: UMIUserSummary;
}
export interface UMIPolicySummary {
  name: string;
  key: string;
}
export interface UMIRoleSummary {
  name: string;
  key: string;
  policy?: UMIPolicySummary;
}
export type UMIUserSummaryList = UMIUserSummary[];
export interface UMIUserSummary {
  userName: string;
  admin?: boolean;
  attributes?: AttributeListType;
  primaryTenant?: UMITenantSummary;
  tenants?: UMITenantSummary[];
  roleData?: ITenantRolePolicyData;
  tenantData?: ITenantUserPolicyData;
}
export enum ItemType {
  TENANT = 'tenant',
  ROLE = 'role',
  POLICY = 'policy',
  TENANT_POLICY = 'tenantPolicy',
  TENANT_ROLE = 'tenantRole',
  TENANT_USER = 'tenantUser',
  ROLE_POLICY = 'rolePolicy',
  ROLE_USER = 'roleUser',
  APP_CONFIGURATION = 'appConfiguration',
}

/**
 * Defines common features that are available on all items
 */
export interface UMIBaseItem {
  key: string;
  id: string;
  type: ItemType;
  name?: string;
  description?: string;
}

export enum PermissionType {
  READ = 'READ',
  WRITE = 'WRITE',
  DELETE = 'DELETE',
  ALL = 'ALL',
  DENY = 'DENY',
}

export interface ResourcePermissions {
  [name: string]: PermissionType[];
}

export interface AppFeaturePermissions {
  featureOn?: boolean;
  supplementaryFeatures?: string[];
  resource: ResourcePermissions;
  patterns: ResourcePermissions;
  attributes?: ResourcePermissions;
  prompts?: ResourcePermissions;
}
export interface ConnectSDKPermissions {
  featureOn: boolean;
  associativePermissions?: string[];
}
export interface ConnectListPermissions {
  permissions: ConnectResourcePermissions;
}
export interface ConnectResourcePermissions {
  filterOn?: boolean;
  filterType: ConnectItemFilterType | ConnectUserFilterType;
  resource: ResourcePermissions;
  patterns: ResourcePermissions;
}

export enum ConnectUserFilterType {
  USER_HIERARCHY = 'USER_HIERARCHY',
  USER_NAME = 'USER_NAME',
  SECURITY_GROUP = 'SECURITY_GROUP',
  ROUTING_PROFILE = 'ROUTING_PROFILE',
}
export interface AppHierarchyGroup extends HierarchyGroup {
  Level1?: AppHierarchyGroup[];
  Level2?: AppHierarchyGroup[];
  Level3?: AppHierarchyGroup[];
  Level4?: AppHierarchyGroup[];
  Level5?: AppHierarchyGroup[];
}
export enum ConnectItemFilterType {
  NAME = 'NAME',
  ID = 'ID',
  ARN = 'ARN',
}
export interface IUserHierarchyFilter {
  Level1: string;
  Level2: string;
  Level3: string;
  Level4: string;
  Level5: string;
}
export class UserHierarchyFilter {
  Level1 = '';
  Level2 = '';
  Level3 = '';
  Level4 = '';
  Level5 = '';
}
export enum FeatureTypes {
  userManagement = 'userManagement',
  entryManagement = 'entryManagement',
  menuManagement = 'menuManagement',
  queueTreatmentManagement = 'queueTreatmentManagement',
  configSetManagement = 'configSetManagement',
  hoursOfOperationManagement = 'hoursOfOperationManagement',
  closuresManagement = 'closuresManagement',
  holidaysManagement = 'holidaysManagement',
  syncManagement = 'syncManagement',
  realTimeMetrics = 'realTimeMetrics',
  realTimeMetricsQueues = 'realTimeMetricsQueues',
  realTimeMetricsRoutingProfiles = 'realTimeMetricsProfiles',
  realTimeMetricsAgents = 'realTimeMetricsAgents',
  historicalMetrics = 'historicalMetrics',
  contactSearch = 'contactSearch',
  agentManagement = 'agentManagement',
}
export interface UMIPolicy {
  app: {
    userManagement: {
      featureOn?: boolean;
      supplementaryFeatures?: string[];
      users: AppFeaturePermissions;
      roles: AppFeaturePermissions;
      policies: AppFeaturePermissions;
    };
    entryManagement: AppFeaturePermissions;
    menuManagement: AppFeaturePermissions;
    queueTreatmentManagement: AppFeaturePermissions;
    configSetManagement: AppFeaturePermissions;
    hoursOfOperationManagement: AppFeaturePermissions;
    closuresManagement: AppFeaturePermissions;
    holidaysManagement: AppFeaturePermissions;
    syncManagement: AppFeaturePermissions;
    //realTimeMetrics: AppFeaturePermissions;
    realTimeMetricsQueues: AppFeaturePermissions;
    realTimeMetricsAgents: AppFeaturePermissions;
    realTimeMetricsRoutingProfiles: AppFeaturePermissions;
    //historicalMetrics: AppFeaturePermissions;
    contactSearch: AppFeaturePermissions;
    connectUserManagement: AppFeaturePermissions;
  };
  connect: {
    resource: {
      connectUsers?: ConnectResourcePermissions;
      listPrompts: ConnectResourcePermissions;
      listRoutingProfiles: ConnectResourcePermissions;
      listContactFlows: ConnectResourcePermissions;
      listPhoneNumbers: ConnectResourcePermissions;
      listQueues: ConnectResourcePermissions;
      listSecurityProfiles: ConnectResourcePermissions;
      listHierarchyGroups: ConnectResourcePermissions;
      listUsers: ConnectResourcePermissions;
    };
    actions: {
      updateUserRoutingProfile: ConnectSDKPermissions;
    };
  };
}

export class UMDefaultPolicy implements UMIPolicy {
  app = {
    userManagement: {
      featureOn: false,
      users: {
        resource: {},
        patterns: {},
      },
      roles: {
        resource: {},
        patterns: {},
      },
      policies: {
        resource: {},
        patterns: {},
      },
    },
    entryManagement: {
      featureOn: false,
      resource: {},
      patterns: {},
    },
    menuManagement: {
      featureOn: false,
      resource: {},
      patterns: {},
    },
    queueTreatmentManagement: {
      featureOn: false,
      resource: {},
      patterns: {},
    },
    configSetManagement: {
      featureOn: false,
      filterType: 'name',
      resource: {},
      patterns: {},
    },
    hoursOfOperationManagement: {
      featureOn: true,
      filterType: 'name',
      resource: {},
      patterns: {},
    },
    closuresManagement: {
      featureOn: false,
      filterType: 'name',
      resource: {},
      patterns: {},
    },
    holidaysManagement: {
      featureOn: false,
      filterType: 'name',
      resource: {},
      patterns: {},
    },
    syncManagement: {
      featureOn: false,
      resource: {},
      patterns: {},
    },
    // realTimeMetrics: {
    //   featureOn: false,
    //   resource: {},
    //   patterns: {},
    // },
    realTimeMetricsQueues: {
      featureOn: false,
      resource: {},
      patterns: {},
    },
    realTimeMetricsAgents: {
      featureOn: false,
      resource: {},
      patterns: {},
    },
    realTimeMetricsRoutingProfiles: {
      featureOn: false,
      resource: {},
      patterns: {},
    },
    // historicalMetrics: {
    //   featureOn: false,
    //   resource: {},
    //   patterns: {},
    // },
    connectUserManagement: {
      featureOn: false,
      resource: {},
      patterns: {},
    },
    contactSearch: {
      featureOn: false,
      resource: {},
      patterns: {},
    },
  };
  connect = {
    resource: {
      listPrompts: {
        filterType: ConnectItemFilterType.NAME,
        resource: {},
        patterns: {},
      },
      listRoutingProfiles: {
        filterType: ConnectItemFilterType.NAME,
        resource: {},
        patterns: {},
      },
      listSecurityProfiles: {
        filterType: ConnectItemFilterType.NAME,
        resource: {},
        patterns: {},
      },
      listContactFlows: {
        filterType: ConnectItemFilterType.NAME,
        resource: {},
        patterns: {},
      },
      listPhoneNumbers: {
        filterType: ConnectItemFilterType.NAME,
        resource: {},
        patterns: {},
      },
      listQueues: {
        filterType: ConnectItemFilterType.NAME,
        resource: {},
        patterns: {},
      },
      listHierarchyGroups: {
        filterType: ConnectItemFilterType.NAME,
        resource: {},
        patterns: {},
      },

      listUsers: {
        filterType: ConnectUserFilterType.USER_HIERARCHY,
        resource: {},
        patterns: {},
      },
    },
    actions: {
      updateUserRoutingProfile: {
        featureOn: false,
        associativePermissions: ['listUsers', 'listHierarchyGroups', 'listRoutingProfiles', 'listSecurityProfiles'],
      },
    },
  };
}

/**
 * Schematizing Entities
 */
export class UMBaseItem implements UMIBaseItem {
  key: string;
  id: string;
  type: ItemType;
  name?: string;
  description?: string;
  tenantKey?: string;
  global?: boolean;

  constructor(type: ItemType) {
    this.type = type;
  }
}
export interface UMIAppConfigData {
  tenancyOn: boolean;
  [name: string]: any;
}
export class UMTenantItem extends UMBaseItem {
  prefix?: string;
  constructor(input?: { [String in keyof UMTenantItem]?: any }) {
    super(ItemType.TENANT);
    if (input) Object.assign(this, input);
    this.id = input.key;
  }
}
export class UMRoleItem extends UMBaseItem {
  constructor(input?: { [String in keyof UMRoleItem]?: any }) {
    super(ItemType.ROLE);
    if (input) Object.assign(this, input);
    this.id = input.key;
  }
}
export class UMPolicyItem extends UMBaseItem {
  data: UMIPolicy;
  constructor(input?: { [String in keyof UMPolicyItem]?: any }) {
    super(ItemType.POLICY);
    if (input) Object.assign(this, input);
    this.id = input.key;
  }
}
export class UMTenantPolicyItem extends UMBaseItem {
  constructor(
    tenantKey: string,
    tenantName,
    policyKey: string,
    policyName: string,
    name: string = null,
    description: string = null
  ) {
    super(ItemType.TENANT_POLICY);
    this.key = tenantKey;
    this.id = policyKey;
    this.description = description ? description : `Association of policy ${policyName} to tenant ${tenantName}`;
    this.name = name ? name : `${tenantName}-${policyName}-association`;
  }
}
export class UMTenantRoleItem extends UMBaseItem {
  constructor(
    tenantKey: string,
    tenantName,
    roleKey: string,
    roleName: string,
    name: string = null,
    description: string = null
  ) {
    super(ItemType.TENANT_ROLE);
    this.key = tenantKey;
    this.id = roleKey;
    this.description = description ? description : `Association of role ${roleName} to tenant ${tenantName}`;
    this.name = name ? name : `${tenantName}-${roleName}-association`;
  }
}
export class UMAppConfiguration extends UMBaseItem {
  key = '__vf-app-configuration__';
  id = '__vf-app-configuration__';
  data: UMIAppConfigData = {
    tenancyOn: false,
  };
  constructor() {
    super(ItemType.APP_CONFIGURATION);
  }
}
export class UMTenantUserItem extends UMBaseItem {
  primary: boolean = false;
  constructor(tenantKey: string, userName: string, primary: boolean = false) {
    super(ItemType.TENANT_USER);
    this.key = tenantKey;
    this.id = userName;
    this.primary = primary;
    this.description = `Association of user ${userName} to tenant ${tenantKey}`;
    this.name = `${tenantKey}-${userName}-association`;
  }
}

export class UMRolePolicyItem extends UMBaseItem {
  constructor(roleKey: string, policyKey: string, tenantKey?: String) {
    super(ItemType.ROLE_POLICY);
    this.key = roleKey;
    this.id = policyKey;
    this.description = `Association of policy ${policyKey} to role ${roleKey}`;
    this.name = `${roleKey}-${policyKey}-association`;
    this.tenantKey = tenantKey ? tenantKey : null;
  }
}
export class UMRoleUserItem extends UMBaseItem {
  constructor(userName: string, roleKey: string, tenantKey?: string) {
    super(ItemType.ROLE_USER);
    this.key = roleKey;
    this.id = userName;
    this.tenantKey = tenantKey ? tenantKey : null;
    this.description = `Association of user ${userName} to role ${roleKey}`;
    this.name = roleKey;
  }
}
export interface UMIQueryResults {
  tenants: UMTenantItem[];
  tenantPolicies: UMTenantPolicyItem[];
  tenantUsers: UMTenantUserItem[];
  tenantRoles: UMTenantRoleItem[];
  roles: UMRoleItem[];
  policies: UMPolicyItem[];
  roleUsers: UMRoleUserItem[];
  rolePolicies: UMRolePolicyItem[];
}
export interface ITenantRolePolicyData {
  tenantKey?: string;
  tenantPrimary?: boolean;
  tenantName?: string;
  tenantPolicyKey?: string;
  tenantPolicyName?: string;
  tenantPolicyData?: UMIPolicy;
  roleKey: string;
  roleName: string;
  rolePolicyKey?: string;
  rolePolicyData: UMIPolicy;
  rolePolicyName?: string;
}
export type ITenantUserPolicyData = ITenantRolePolicyData[];

export type UMIUserList = UMIUser[];
export interface UMIUser {
  user: UMIUserSummary;
  role?: UMRoleItem;
  associations?: UserAssociations;
  permissions: PermissionByType;
}
export interface PermissionByType {
  [PermissionType.READ]?: boolean;
  [PermissionType.WRITE]?: boolean;
  [PermissionType.DELETE]?: boolean;
  [PermissionType.ALL]?: boolean;
}

export interface UMIRole extends PermissionsAttr {
  role: UMRoleItem;
  policy?: UMPolicyItem;
  users?: string[];
}

export type UMIPolicyList = UMIPolicyVM[];

export interface UMIPolicyVM extends PermissionsAttr {
  policy: UMPolicyItem;
}

export type UMIRoleList = UMIRole[];
export interface CustomUserClaimsData {
  features: string[];
  tenantKey?: string;
  tenantPrimary?: boolean;
  tenantName?: string;
  tenantPolicyKey?: string;
  rolePolicyKey?: string;
  rolePolicyName?: string;
  roleName?: string;
}
export interface ITenant {
  name: string;
  tenantKey: string;
}
export type CustomUserClaims = {
  primaryTenant?: CustomUserClaimsData;
  tenancyOn?: boolean;
  tenants?: UMITenantSummary[];
  features: string[];
  policyData: CustomUserClaimsData[];
};

// export interface PermissionsDict {
//   [PermissionType.READ]?: string[] | '*' | boolean;
//   [PermissionType.WRITE]?: string[] | '*' | boolean;
//   [PermissionType.DENY]?: string[] | '*' | boolean;
//   [PermissionType.ALL]?: string[] | '*' | boolean;
//   [PermissionType.DELETE]?: string[] | '*' | boolean;
// }
export interface PermissionsDict {
  [PermissionType.READ]?: boolean;
  [PermissionType.WRITE]?: boolean;
  [PermissionType.DENY]?: boolean;
  [PermissionType.ALL]?: boolean;
  [PermissionType.DELETE]?: boolean;
}
export interface PermissionsDictList {
  [PermissionType.READ]?: string[];
  [PermissionType.WRITE]?: string[];
  [PermissionType.DENY]?: string[];
  [PermissionType.ALL]?: string[];
  [PermissionType.DELETE]?: string[];
}
export interface PermissionsAttr {
  /**
   * Added when RBAC is enabled
   */
  permissions?: PermissionsDict;
}

export interface UMIFeatureAction {
  resource: string;
  feature: string;
  permission: PermissionType;
}
export interface UMIPolicyDetail extends UMPolicyItem {
  roleAssociations?: UMRoleItem[];
  tenantAssociations?: UMTenantItem[];
}
export interface UMITenantDetail extends UMTenantItem {
  users: string[];
  roles: UMIRoleDetail[];
  policy: UMPolicyItem;
}
export interface UMIRoleDetail extends UMRoleItem {
  users: UMRoleUserItem[];
  policy: UMPolicyItem;
}

export interface IUserAssociationsDict {
  [userName: string]: UserAssociations;
}
export interface UserAssociations {
  tenants?: TenantAssociations[];
  roles: UMRoleItem[];
}
export interface TenantAssociations {
  tenant: UMTenantItem;
  role: UMRoleItem;
}

/**
 * Connect SDK Overrides
 */

export type VFUserSummary = UserSummary & PermissionsAttr;
export type VFUser = User & PermissionsAttr;
export type VFUserSummaryList = VFUserSummary[];
export type VFSecurityProfileSummary = SecurityProfileSummary & PermissionsAttr;
export type VFSecurityProfileSummaryList = VFSecurityProfileSummary[];
export type VFContactFlowSummary = ContactFlowSummary & PermissionsAttr;
export type VFPromptSummary = PromptSummary & PermissionsAttr;
export type VFPhoneNumberSummary = PhoneNumberSummary & PermissionsAttr;
export type VFQueueSummary = QueueSummary & PermissionsAttr;
export type VFRoutingProfileSummary = RoutingProfileSummary &
  PermissionsAttr & {
    queues?: VFQueueSummary[];
    /**
     * Added by caching implementation. This is raw data retrieved from API and stored in DDB.
     * TODO: Maybe merge/convert RoutingProfileQueueConfigSummary to VFQueueSummary
     */
    queueSummaries?: RoutingProfileQueueConfigSummary[];
  };
export type VFHierarchyGroupSummary = HierarchyGroupSummary & PermissionsAttr;
export type VFQueue = Connect.Queue & PermissionsAttr;

//-------

/**
 * Metrics
 */

export interface IRealTimeMetricsRow {
  id: string;
  name: string;
  AGENTS_AFTER_CONTACT_WORK: number;
  AGENTS_AVAILABLE: number;
  AGENTS_ERROR: number;
  AGENTS_NON_PRODUCTIVE: number;
  AGENTS_ONLINE: number;
  AGENTS_ON_CONTACT: number;
  AGENTS_STAFFED: number;
  CONTACTS_IN_QUEUE: number;
  CONTACTS_SCHEDULED: number;
  OLDEST_CONTACT_AGE: number;
  SLOTS_ACTIVE: number;
  SLOTS_AVAILABLE: number;
}
export interface IHistoricalMetricsRow {
  id: string;
  name: string;
  CONTACTS_QUEUED: number;
  CONTACTS_HANDLED: number;
  CONTACTS_ABANDONED: number;
  CONTACTS_CONSULTED: number;
  CONTACTS_AGENT_HUNG_UP_FIRST: number;
  CONTACTS_HANDLED_INCOMING: number;
  CONTACTS_HANDLED_OUTBOUND: number;
  CONTACTS_HOLD_ABANDONS: number;
  CONTACTS_TRANSFERRED_IN: number;
  CONTACTS_TRANSFERRED_OUT: number;
  CONTACTS_TRANSFERRED_IN_FROM_QUEUE: number;
  CONTACTS_TRANSFERRED_OUT_FROM_QUEUE: number;
  CONTACTS_MISSED: number;
  CALLBACK_CONTACTS_HANDLED: number;
  API_CONTACTS_HANDLED: number;
  OCCUPANCY: string;
  HANDLE_TIME: string;
  AFTER_CONTACT_WORK_TIME: string;
  QUEUED_TIME: string;
  ABANDON_TIME: string;
  QUEUE_ANSWER_TIME: string;
  HOLD_TIME: string;
  INTERACTION_TIME: string;
  INTERACTION_AND_HOLD_TIME: string;
  SERVICE_LEVEL: number;
}
export type IAggregratedSDKMetrics = IRealTimeMetricsRow & IHistoricalMetricsRow;

export interface VFGetCurrentMetricDataResponse {
  MetricResults: IRealTimeMetricsRow[];
  DataSnapshotTime: string;
}
export interface VFGetHistoricalMetricDataResponse {
  MetricResults: IHistoricalMetricsRow[];
}

export interface IAggregatedMetrics {
  timestamp: string;
  metrics: IAggregratedSDKMetrics[];
}

export interface IAggregatedMetricsItem {
  key: string;
  id: string;
  metrics: IAggregratedSDKMetrics[];
  updatedAt?: string;
}

export class AggregatedMetrics implements IAggregatedMetrics {
  timestamp: string = null;
  metrics: IAggregratedSDKMetrics[];
  constructor(data: IAggregatedMetrics) {
    this.timestamp = data.timestamp;
    this.metrics = data.metrics.map(m => new AggregatedMetricsItem(m));
  }
}
export class AggregatedAgentMetrics implements IAggregatedMetrics {
  timestamp: string = null;
  metrics: IAggregratedSDKMetrics[];
  constructor(data: IAggregatedMetrics) {
    this.timestamp = data.timestamp;
    this.metrics = data.metrics.map(m => new AggregatedMetricsItem(m));
  }
}
export class AggregatedMetricsItem implements IAggregratedSDKMetrics {
  id: string = null;
  name: string = null;
  CONTACTS_QUEUED: number = null;
  CONTACTS_HANDLED: number = null;
  CONTACTS_ABANDONED: number = null;
  CONTACTS_CONSULTED: number = null;
  CONTACTS_AGENT_HUNG_UP_FIRST: number = null;
  CONTACTS_HANDLED_INCOMING: number = null;
  CONTACTS_HANDLED_OUTBOUND: number = null;
  CONTACTS_HOLD_ABANDONS: number = null;
  CONTACTS_TRANSFERRED_IN: number = null;
  CONTACTS_TRANSFERRED_OUT: number = null;
  CONTACTS_TRANSFERRED_IN_FROM_QUEUE: number = null;
  CONTACTS_TRANSFERRED_OUT_FROM_QUEUE: number = null;
  CONTACTS_MISSED: number = null;
  CALLBACK_CONTACTS_HANDLED: number = null;
  API_CONTACTS_HANDLED: number = null;
  OCCUPANCY: string = null;
  HANDLE_TIME: string = null;
  AFTER_CONTACT_WORK_TIME: string = null;
  QUEUED_TIME: string = null;
  ABANDON_TIME: string = null;
  QUEUE_ANSWER_TIME: string = null;
  HOLD_TIME: string = null;
  INTERACTION_TIME: string = null;
  INTERACTION_AND_HOLD_TIME: string = null;
  SERVICE_LEVEL: number = null;
  AGENTS_AFTER_CONTACT_WORK: number = null;
  AGENTS_AVAILABLE: number = null;
  AGENTS_ERROR: number = null;
  AGENTS_NON_PRODUCTIVE: number = null;
  AGENTS_ONLINE: number = null;
  AGENTS_ON_CONTACT: number = null;
  AGENTS_STAFFED: number = null;
  CONTACTS_IN_QUEUE: number = null;
  CONTACTS_SCHEDULED: number = null;
  OLDEST_CONTACT_AGE: number = null;
  SLOTS_ACTIVE: number = null;
  SLOTS_AVAILABLE: number = null;
  constructor(input?: { [String in keyof AggregatedMetricsItem]?: any }) {
    if (input) Object.assign(this, input);
  }
}
export type AggregatedAgentMetricsItem = AgentRealTimeMetricOutgoing & AgentConfigurationItem;
export type AggregatedAgentMetricsList = AggregatedAgentMetricsItem[];

export const DISCONNECT_REASONS = [
  {
    value: 'CUSTOMER_DISCONNECT',
    text: 'Customer Disconnect',
    description: 'Customer disconnected first.',
  },
  {
    value: 'AGENT_DISCONNECT',
    text: 'Agent Disconnect',
    description: 'Agent disconnected when the contact was still on the call or Agent marked the task as complete.',
  },
  {
    value: 'THIRD_PARTY_DISCONNECT',
    text: 'Third Party Disconnect',
    description:
      'In a third-party call, after the agent has left, the third-party disconnected the call while the contact was still on the call.',
  },
  {
    value: 'TELECOM_PROBLEM',
    text: 'Telecom Problem',
    description:
      'Disconnected due to an issue with connecting the call from the carrier, network congestion, network error, etc.',
  },
  {
    value: 'CONTACT_FLOW_DISCONNECT',
    text: 'Contact Flow Disconnect',
    description: 'Call was disconnected in a flow or Task was disconnected or completed by a flow.',
  },
  {
    value: 'EXPIRED',
    text: 'Expired',
    description: 'Task expired automatically because it was not assigned or completed within 7 days.',
  },
  {
    value: 'API',
    text: 'Api',
    description: 'The StopContact API was called to end the task.',
  },
  {
    value: 'OTHER',
    text: 'Other',
    description: 'This includes any reason not explicitly covered by the previous codes.',
  },
];
export const INITIATION_METHODS = [
  {
    value: 'INBOUND',
    text: 'Inbound',
    description: 'The customer initiated voice (phone) contact with your contact center.',
  },
  {
    value: 'OUTBOUND',
    text: 'Outbound',
    description: 'An agent initiated voice (phone) contact with the customer, by using the CCP to call their number.',
  },
  {
    value: 'TRANSFER',
    text: 'Transfer',
    description:
      'The customer was transferred by an agent to another agent or to a queue, using quick connects in the CCP. This results in a new CTR being created.',
  },
  {
    value: 'CALLBACK',
    text: 'Callback',
    description: 'The customer was contacted as part of a callback flow.',
  },
  {
    value: 'API',
    text: 'API',
    description:
      'The contact was initiated with Amazon Connect by API. This could be an outbound contact you created and queued to an agent, using the StartOutboundVoiceContact API, or it could be a live chat that was initiated by the customer with your contact center, where you called the StartChatConnect API.',
  },
  {
    value: 'QUEUE_TRANSFER',
    text: 'Queue Transfer',
    description:
      'While the customer was in one queue (listening to Customer queue flow), they were transferred into another queue using a contact flow block.',
  },
  {
    value: 'DISCONNECT',
    text: 'Disconnect',
    description:
      'When a Set disconnect flow block is triggered, it specifies which contact flow to run after a disconnect event during a contact.',
  },
];

export interface IContactSearchRow {
  contactId: string;
  channel: string;
  initiationTimestamp: string;
  systemPhoneNumber: string;
  queue: string;
  agent: string;
  agentName: string;
  agentFirstName: string;
  agentLastName: string;
  routingProfile: string;
  recordingTranscript: string;
  recordingTranscriptLocation?: string;
  recordingTranscriptStatus?: string;
  recordingTranscriptType?: string;
  customerPhoneNumber: string;
  disconnectTimestamp: string;
  contactDuration: number;
  connectToAgentTimestamp: string;
  acwStartTimestamp: string;
  acwEndTimestamp: string;
  agentInteractionDuration: number;
  agentConnectionAttempts: number;
  numberHolds: number;
  isTransferredOut: boolean;
  initiationMethod: string;
  disconnectReason: string;
  firstContactFlowName: string;
  initialContactId: string;
  previousContactId: string;
  nextContactId: string;
  firstContactFlowId: string;
}

export class ContactSearchRow implements IContactSearchRow {
  contactId: string;
  channel: string;
  initiationTimestamp: string;
  systemPhoneNumber: string;
  queue: string;
  agent: string;
  agentName: string;
  agentFirstName: string;
  agentLastName: string;
  routingProfile: string;
  recordingTranscript: string;
  recordingTranscriptLocation?: string;
  recordingTranscriptStatus?: string;
  recordingTranscriptType?: string;
  customerPhoneNumber: string;
  disconnectTimestamp: string;
  contactDuration: number;
  connectToAgentTimestamp: string;
  acwStartTimestamp: string;
  acwEndTimestamp: string;
  agentInteractionDuration: number;
  agentConnectionAttempts: number;
  numberHolds: number;
  isTransferredOut: boolean;
  initiationMethod: string;
  disconnectReason: string;
  firstContactFlowName: string;
  initialContactId: string;
  previousContactId: string;
  nextContactId: string;
  firstContactFlowId: string;
}

export interface IContactSearchQueryResponse {
  ready: boolean;
  results?: IContactSearchRow[];
  totalNumRows?: number;
}
export interface IContactDetailQueryResponse {
  ready: boolean;
  results?: {
    detail: IContactSearchRow;
    customAttributes: any;
  };
}
export interface IStartContactSearchQueryResponse {
  ok: boolean;
  message?: string;
  queryId?: IContactSearchQueryId;
}
export type IContactSearchQueryId = string;

export interface IContactSearchQuery {
  timeRangeStart: string;
  timeRangeEnd: string;
  channelFilter: {
    voice: boolean;
    chat: boolean;
    task: boolean;
  };
  contactIdFilter: string[] | null;
  customerPhoneFilter: string[] | null;
  disconnectReasonFilter: string[] | null;
  initiationMethodFilter: string[] | null;
  systemPhoneNumberFilter: string[] | null;
  agentFilter: string[] | null;
  queueFilter: string[] | null;
  interactionTimeFilter: {
    min: number;
    max: number;
  } | null;
}

export interface IGetSignedUrlRequest {
  url: string;
}

export interface IGetSignedUrlResponse {
  ok: boolean;
  message?: string;
  url?: string;
}

export interface IContactSearchQueryOptions {
  useOptions: boolean;
  systemPhoneNumbers?: string[];
  queues?: string[];
}
